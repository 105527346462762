<template>
    <div>
        <b-row>
            <b-col lg="3" cols="12" order="2" order-lg="2">
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>
                            Takvimim
                        </h5>
                    </b-card-header>
                    <b-card-body>
                        <b-button block :variant="selectedSyllabus==date?'success':'primary'"
                            class="font-weight-bolder mb-12" v-for="date in dates" :key="date.id"
                            @click="getUserData(date)">
                            {{date.class.name+" / "+date.lesson.name}}<br />
                            <span>{{date.startTime+' - '+date.endTime}}</span>
                        </b-button>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col lg="9" cols="12" order="2" order-lg="2">
                <b-card v-if="users" no-body class="card-company-table">
                    <b-table :items="users" responsive :fields="fields" class="mb-0">
                        <!-- Id -->
                        <template #cell(id)="data">
                            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                        </template>

                        <!-- Id -->
                        <template #cell(username)="data">
                            <span class="font-weight-bolder mb-12">{{
                            (data.item.user!=null)?
                            data.item.user.firstName+' '+data.item.user.lastName:
                            "Kullanıcı Bulunamadı"
                            }}</span>
                        </template>

                        <template #cell(isAttendance)="data">
                            <b-badge pill :variant="getStatus(data.item.isAttendance)">
                                {{getStatusText(data.item.isAttendance)}}
                            </b-badge>
                        </template>
                        <template #cell(action)="data">
                            <b-button-group>
                                <b-button variant="success" @click="setUserData(data.item,true)">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                                <b-button variant="danger" @click="setUserData(data.item,false)">
                                    <feather-icon icon="XIcon" />
                                </b-button>
                            </b-button-group>
                        </template>
                    </b-table>
                    <b-button @click="allUserSetData">
                        Tümünü Geldi Olarak İşaretle
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BButtonGroup
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        BButtonGroup,
        flatPickr,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress
    },
    data() {
        return {
            fields: [
                { key: 'schoolNumber', label: 'Okul Numarası' },
                { key: 'username', label: 'Öğrenci Adı Soyadı' },
                { key: 'action', label: 'İşlem' },
                { key: 'isAttendance', label: 'Durum' }
            ],
            users: [],
            dates: [],
            selectedSyllabus: ""
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        getStatus(status) {
            switch (status) {
                case "NotCame":
                    return 'light-danger';
                case "Empty":
                    return 'light-info';
                case "Came":
                    return 'light-success';
            }
        },
        getStatusText(status) {
            switch (status) {
                case "NotCame":
                    return 'Gelmedi';
                case "Empty":
                    return 'Belirtilmedi';
                case "Came":
                    return 'Geldi';
            }
        },
        async getDate() {
            var response = await this.$http.get("Teacher/DailySyllabus");
            this.dates = response.data.data;
        },
        async getUserData(syllabus) {
            this.selectedSyllabus = syllabus;
            var response = await this.$http.get("Report/StudentAttendance/" + syllabus.id);
            this.users = response.data.data;
        },
        async allUserSetData() {
            this.users.forEach(user => {
                this.setUserData(user, true);
            })
        },
        async setUserData(user, isAttence) {
            var response = await this.$http.put("Report/AddStudentAttendance/", {
                userId: user.user.id,
                syllabusId: this.selectedSyllabus.id,
                isAttendance: isAttence
            });
            this.getUserData(this.selectedSyllabus);
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
